import { EquipmentFuel } from './emission-factor.types';

export type HistoricalEquipmentType = {
  type: string;
  fuels: EquipmentFuel[];
  hasEfficiency: boolean;
  selectable: boolean;
};

export type GetHistoricalEquipmentTypesRsp = {
  types: HistoricalEquipmentType[];
};

export type GetHistoricalTypesParams = Partial<{
  location: string;
  year: number;
}>;
