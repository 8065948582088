import {
  DeclicKPI,
  Equipment,
  EquipmentEmissions,
  EquipmentsAndTheirEmissions,
  KPIReport,
  ValueByYear,
} from '@declic/types';
import { createStore, propsFactory } from '@ngneat/elf';
import { Coerce } from 'declic-app/common/coercion.utils';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { withError, withHyperjump, withLoading } from './factories';

export const {
  withReport,
  setReport,
  getReport,
  selectReport,
  updateReport,
  resetReport,
} = propsFactory('report', {
  initialValue: {
    summaryYears: [2024, 2025, 2030, 2035, 2040],
    summaryEmissions: {},
  } as KPIReport,
});

export const { withTotal, selectTotal, updateTotal, getTotal } = propsFactory(
  'total',
  {
    initialValue: null as EquipmentEmissions,
  },
);

export const kpisStore = createStore(
  { name: 'kpis' },
  withLoading(),
  withReport(),
  withError(),
  withHyperjump(),
  withTotal(),
);

export const KPIStoreQueries = {
  selectIsInFailed,
  selectEquipments,
  selectEqEmissions,
  selectYears,
};

function selectYears(): Observable<number[]> {
  return kpisStore.pipe(selectReport(), pluck('years'));
}

function selectEquipments(): Observable<Equipment[]> {
  return selectEqEmissions().pipe(
    map((eqs) => Coerce.toObj(eqs)),
    map((eqsAndEms) => Object.keys(eqsAndEms) as Equipment[]),
  );
}

function selectEqEmissions(): Observable<EquipmentsAndTheirEmissions> {
  return kpisStore.pipe(selectReport(), pluck('equipmentEmissions'));
}

function selectIsInFailed(kpi: DeclicKPI): Observable<boolean> {
  return kpisStore.pipe(
    selectReport(),
    pluck('failedKPIs'),
    map((kpis) => Coerce.toArr(kpis).includes(kpi)),
  );
}
