import { ValuePerYear } from '@declic/types';

export type MultipleData = Partial<{
  data: ValuePerYear;
  label: string;
  background: string;
}>;

export type BarChartConfig = Partial<{
  data: MultipleData[];
  xAxisTitle: string;
  yAxisTitle: string;
  chartTitle: string;
  labels: any[];
  displayLegends: boolean;
}>;
