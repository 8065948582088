import {
  BasicEquipment,
  HasAssetEfficiency,
  HasCapacity,
  HasFuels,
  HasYearlyProduction,
} from './equipment.types';

export type Boiler = BasicEquipment &
  HasAssetEfficiency &
  HasYearlyProduction &
  HasFuels &
  HasCapacity;

export type BoilerForm = Pick<
  Boiler,
  'name' | 'assetEfficiency' | 'yearlyProduction' | 'fuels' | 'capacity'
>;
