import { Coerce } from 'declic-app/common';
import { EndpointProvider } from 'declic-app/services/endpoints';
import { Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@declic/env';
import {
  Equipments,
  GBUESSource,
  GBUESSourceKind,
  GBUESSourcesRsp,
  GBUESState,
  SourceType,
} from '@declic/types';
import { withCache } from '@ngneat/cashew';
import { createStore, select, setProp, withProps } from '@ngneat/elf';

export const gbuEsStore = createStore(
  { name: 'gbu-es' },
  withProps<GBUESState>({
    sources: {
      electricities: [
        { ef: { 2021: 1 }, name: 'hello', type: SourceType.GREEN },
      ],
      fuels: [],
      inputTypes: [],
      refrigerants: [],
    } as unknown as GBUESSourcesRsp,
  }),
);

@Injectable({ providedIn: 'root' })
export class GbuEsService {
  private http = inject(HttpClient);
  private endpoints = inject(EndpointProvider);

  getSources(
    equipment: Equipments,
    location?: string,
    years?: number[],
  ): Observable<unknown> {
    location = Coerce.toString(location).toLowerCase().replace(/\s/g, '');
    const startYear = Coerce.toArr(years).at(0);
    const endYear = Coerce.toArr(years).at(-1);

    return this.http
      .get(this.endpoints.forGbuEsSources(equipment), {
        context: withCache(),
        params: {
          location,
          startYear,
          endYear,
          v: environment.appVersion,
        },
      })
      .pipe(
        tap((rsp: GBUESSourcesRsp) =>
          gbuEsStore.update(setProp('sources', rsp)),
        ),
      );
  }
}

function pluck(slice: keyof GBUESSourcesRsp, id: string): GBUESSourceKind {
  return Coerce.toObj(
    gbuEsStore.value.sources[slice]?.find((src) => src.id === id),
  ) as GBUESSourceKind;
}

function selectFuels(): Observable<GBUESSource[]> {
  return gbuEsStore.pipe(
    select((state) =>
      Array.isArray(state.sources.fuels) ? state.sources.fuels : [],
    ),
  );
}

export const ESStoreQueries = {
  pluck,
  selectFuels,
};
